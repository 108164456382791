import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

import "./mobile-header.styles.scss";

const MobileHeader = () => {
  const [opened, setOpened] = useState(false);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  return (
    <div className="mobile-header">
      <div className="menu-icon">
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleMenu}
          color={"lightgray"}
        />
      </div>
      {opened && (
        <div className="mobile-header-link-container">
          <Link
            to="biography"
            smooth={true}
            className="header-link"
            onClick={toggleMenu}
          >
            Bio
          </Link>
          <Link
            to="research"
            smooth={true}
            className="header-link"
            onClick={toggleMenu}
          >
            Research
          </Link>
          <Link
            to="outreach"
            smooth={true}
            className="header-link"
            onClick={toggleMenu}
          >
            Outreach
          </Link>
          <Link
            to="media"
            smooth={true}
            className="header-link"
            onClick={toggleMenu}
          >
            Media
          </Link>
          <Link
            to="contact"
            smooth={true}
            className="header-link"
            onClick={toggleMenu}
          >
            Contact
          </Link>
          <a
            className="header-link"
            href="https://firebasestorage.googleapis.com/v0/b/jgarciamejia-website.appspot.com/o/cv.pdf?alt=media&token=a36fe2a8-10e7-45e3-8efe-1bcdd7725ffc"
            target="_blank"
            rel="noopener noreferrer"
          >
            CV
          </a>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
