import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChain } from "@fortawesome/free-solid-svg-icons";

import "./research-section.styles.scss";

const ResearchSection = () => {
  return (
    <div className="research section centered">
      <div className="section-container">
        <h1>RESEARCH</h1>
        <p>
          My research focuses on the development of novel astronomical
          instrumentation to enable the study of exoplanets. I started working
          in astronomical instrumentation as an undergraduate Astrophysics &
          Physics major at Harvard. I worked with Dr. Andrew Szentgyorgyi to
          carry out thermal enclosure upgrades for the TRES Spectrograph’s Front
          End, helped install the KiwiSpec Spectrograph on MINERVA, and built an
          11GHz antenna to carry out the first CMB temperature measurements at
          this frequency.
        </p>
        <p>
          As a graduate student, I decided to become the PI of the{" "}
          <i>Tierras</i> Observatory, as well as a co-investigator in the FIOS
          Project. Read more about the goals and status of these projects below!{" "}
        </p>
        <div className="research-links-container">
          <div
            className="research-link"
            style={{
              backgroundImage: `url("https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/tierras-thumbnail_300x200.jpg")`,
            }}
          >
            <div className="opaque-overlay">
              <h2>
                <i>Tierras</i>
              </h2>
              <p>
                Tierras Observatory is a new and innovative ultra-precise
                photometer reaching 250 ppm precision from the ground.{" "}
              </p>
              <div className="textalign-r">
                <Link to="/tierras">
                  Learn more <FontAwesomeIcon icon={faChain} color="#00c8ff" />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="research-link"
            style={{
              backgroundImage: `url("https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/fios-thumbnail_300x200.jpg")`,
            }}
          >
            <div className="opaque-overlay">
              <h2>FIOS</h2>
              <p>
                FIOS is a prototype instrument designed to reach R=300,000
                resolution and enable the detection of molecular oxygen with the
                GMT.{" "}
              </p>
              <div className="textalign-r">
                <Link to="/fios">
                  Learn more <FontAwesomeIcon icon={faChain} color="#00c8ff" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchSection;
