import React from "react";
import "./biography-section.styles.scss";

const BiographySection = () => {
  return (
    <div className="section biography centered">
      <div className="section-container">
        <h1>BIOGRAPHY</h1>
        <p>
          Hello! Thanks for visiting my website. My name is{" "}
          <a
            href="https://www.spanishdict.com/translate/juliana%20garc%C3%ADa%20mej%C3%ADa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Juliana García-Mejía
          </a>
          .
        </p>
        <p>
          I am a combined 51 Pegasi B and Pappalardo postdoctoral fellow at the
          Massachusetts Institute of Technology. I earned my PhD from Harvard in
          May of 2023.
        </p>
        <p>
          I am broadly interested in developing novel astronomical
          instrumentation to enable the study of exoplanets, their atmospheres,
          and their low mass stellar hosts.  I am the PI of The <i>Tierras</i>
           Observatory, a new 1.3-m ultra-precise fully-automated photometer
          located atop Mt. Hopkins, Arizona. Having spent my entire Ph.D.
          building <i>Tierras</i>, I am now working with the expanding{" "}
          <i>Tierras</i>
          team to uncover temperate terrestrial planets, search for Moons around
          exoplanets, and study their low mass stellar hosts. During my postdoc,
          I will also be pursuing the design of a high throughput,
          extremely-high resolution pathfinder spectrograph to enable
          narrow-wavelength atmospheric structure and velocity dynamic studies
          of exoplanets, and to expand cosmochronological and magnetic field
          studies of stars of varied spectral types. In the future, this
          instrument could enable the detection of molecular oxygen in a
          terrestrial exoplanet atmosphere. Stay tuned for future updates!
        </p>
        <p>
          On a personal note, I grew up in the city of Medellín, Colombia, and
          come from a family of proud coffee growers enamored with the night
          skies. My upbringing and experiences have inspired my investment in
          promoting equity and inclusion within academia, with particular
          attention towards engaging young Latin American and Colombian students
          with the millenary question of whether life exists elsewhere in the
          Universe. This goal permeates my outreach and teaching pursuits, and
          is embedded in the name and logo of the <i>Tierras</i> observatory.
        </p>
      </div>
    </div>
  );
};

export default BiographySection;
