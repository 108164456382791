import React from "react";

import "./signature.styles.scss";

const Signature = () => {
  return (
    <div className="cgm-signature">
      <a
        href="https://www.cat-gm.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="cgm-inline-icon"></div>
        <span>Made with care</span>
      </a>
    </div>
  );
};

export default Signature;
