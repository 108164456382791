import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "./tierras-content.styles.scss";

const TierrasContent = () => {
  const [loading, setLoading] = useState(true);

  const mediaLoaded = () => {
    setLoading(false);
  };

  return (
    <div>
      <div
        className="centered"
        style={{
          visibility: loading ? "visible" : "hidden",
          position: "fixed",
          height: "100vh",
        }}
      >
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        <div className="back-arrow">
          <Link to="/">
            <FontAwesomeIcon icon={faArrowLeft} color="#00c8ff" size="2x" />
          </Link>
        </div>
        <div className="tierras-header-container">
          <div className="tierras-header">
            <p>The</p>
            <p className="cursive">
              <i>Tierras</i>
            </p>
            <p>Observatory</p>
          </div>
          <div className="tierras-background-container">
            <video
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={`true`}
              type="video/mp4"
              src="https://jgarciamejia.nyc3.digitaloceanspaces.com/videos/tierras-background.mp4"
              onLoadedData={() => {
                mediaLoaded();
              }}
            ></video>
          </div>
        </div>
        <div className="section-container">
          <p>
            I am the principal investigator (PI) of the <i>Tierras</i>{" "}
            Observatory, a refurbished 1.3-m ultra-precise fully-automated
            photometer located at the F.L. Whipple Observatory atop Mount
            Hopkins, Arizona. I have led all aspects of the observatory’s
            design, construction, and commissioning, bringing Tierras to reality
            and demonstrating its cutting-edge performance on sky.{" "}
            <i>Tierras</i> achieved first light in the fall of 2021. We have
            been engaged in science operations since April of 2022. Out of the
            box, we are achieving 250 ppm on-sky precision (10 min bins)!
          </p>
          <p>
            The design choices that enable our demonstrated precision include:
          </p>
          <div className="tierras-icons">
            <div className="icon-text">
              <img
                src={
                  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/tierras-icon1.png"
                }
                alt="icon"
              />
              <p>
                Four-lens focal reducer and field-flattener to increase the
                field-of-view of the telescope from 0.19 deg to 0.5 degrees on a
                side
              </p>
            </div>
            <div className="icon-text">
              <img
                src={
                  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/tierras-icon2.png"
                }
                alt="icon"
              />
              <p>
                Custom narrow (40 nm) bandpass filter centered around 863.5 nm
                to minimize precipitable water vapor errors known to limit
                ground-based photometry of M dwarfs
              </p>
            </div>
            <div className="icon-text">
              <img
                src={
                  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/tierras-icon3.png"
                }
                alt="icon"
              />
              <p>
                Deep-depletion 4K x 4K CCD with a quantum efficiency of 85% in
                our bandpass, operating in frame transfer mode
              </p>
            </div>
            <div className="icon-text">
              <img
                src={
                  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/research/tierras-icon4.png"
                }
                alt="icon"
              />
              <p>Fully robotic facility</p>
            </div>
          </div>
          <p>
            To read more about the design of Tierras, check out our{" "}
            <a href="https://www.spiedigitallibrary.org/conference-proceedings-of-spie/11445/2561467/The-Tierras-Observatory--An-ultra-precise-photometer-to-characterize/10.1117/12.2561467.short?SSO=1">
              SPIE paper
            </a>
            .
          </p>
          <div className="mt120">
            <h2>
              Science with <i>Tierras</i>
            </h2>
            <p>
              We are starting a three-year survey of M dwarf stars within 15
              parsecs to detect new terrestrial planets that were too small or
              too cool to be found by TESS or previous ground-based efforts, and
              to monitor known exoplanets (both rocky and gaseous) in search of
              satellites and systems of circumstellar rings. Furthermore, a long
              term monitoring campaign will permit <i>Tierras</i> to determine M
              dwarf rotation periods, elucidating the process and timescale over
              which these stars lose their angular momentum.
            </p>
            <p>
              I am preparing a paper summarizing our first-light results. Please
              check back soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TierrasContent;
