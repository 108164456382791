import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./outreach-modal.styles.scss";

const images1 = [
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-3.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-4.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-1.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-2.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-5.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-6.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-7.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/1-8.jpg",
];

const images2 = [
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/2-1.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/2-2.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/2-3.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/2-4.jpg",
];

const images3 = [
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/3-1.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/middleschool/3-2.jpg",
];

const MiddleSchoolModal = () => {
  return (
    <div className="outreach-modal-container">
      <div className="outreach-modal-body">
        <h2>Elementary & Middle School Teaching & Outreach</h2>
        <p>
          <b>
            Recent research shows that students identify (and discard)
            occupational interests and career aspirations as early as middle
            school.
          </b>{" "}
          The scarcity of educational opportunities in astronomy and
          astronomical instrumentation aimed at underrepresented students
          prevents them from visualizing these career paths as viable, and in
          turn robs us of their unique skill sets.{" "}
        </p>
        <p>
          To help ameliorate this problem, I have designed and led numerous
          lab-based, hands-on day-long workshops and week-long summer camps for
          elementary and middle school students from underserved communities in
          the Boston area. Using simple hardware and software tools, we learn
          about the Sun’s properties, the diversity of planets beyond the Solar
          system, their atmospheres, and the search for life in the Universe.
        </p>
        <p>
          I have led these workshops in English and Spanish depending on the
          student demographics, and have done so in collaboration with the{" "}
          <a href="https://edportal.harvard.edu/">Harvard Ed Portal</a>,{" "}
          <a href="https://projectteach.community.harvard.edu/">
            Project Teach
          </a>
          , and{" "}
          <a href="https://www.belmontday.org/">Belmont Day Middle School</a>.
          Below are some examples of my work.
        </p>
        <div className="middleschool-list-item">
          <div>
            <h4>
              Life in the Universe Summer School for Middle School Girls in
              Boston/Allston
            </h4>
            <p>
              Throughout the course of a week, a group of eight young women and
              I used art, media, experimentation, and a flipped classroom
              curriculum to learn about life in the Universe and discuss its
              societal implications. Here are some of my students, their
              projects and insights:
            </p>
          </div>
          <div className="carousel">
            <Carousel
              autoPlay={true}
              interval={5000}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={true}
            >
              {images1.map((image) => (
                <img
                  src={image}
                  alt="Carousel Slide"
                  key={image}
                  height="auto"
                  width="100%"
                  loading="lazy"
                />
              ))}
            </Carousel>
          </div>
        </div>

        <div className="middleschool-list-item">
          <div>
            <h4>How do we detect an exoplanet atmosphere?</h4>
            <p>
              Student Alice Guo (Belmont Day Middle School) and I designed and
              built a multi-day workshop for her capstone science project. We
              began by exploring how planets are detected, went on a virtual
              tour of <i>Tierras</i> Observatory, and then carried out a series
              of experiments at the Harvard Astronomy Lab to learn about how
              exoplanet atmospheres are studied. Here we are having fun:
            </p>
          </div>
          <div className="carousel">
            <Carousel
              autoPlay={true}
              interval={5000}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={true}
            >
              {images2.map((image) => (
                <img
                  src={image}
                  alt="Carousel Slide"
                  key={image}
                  height="auto"
                  loading="lazy"
                />
              ))}
            </Carousel>
          </div>
        </div>
        <div className="middleschool-list-item">
          <div>
            <h4>WorldWide Telescope Ambassador</h4>
            <p>
              Since 2015, I have served as an ambassador for the{" "}
              <a href="https://worldwidetelescope.github.io/editions/2022/">
                WorldWide Telescope
              </a>{" "}
              (WWT), a web and desktop based suite of free and open source
              software and data sets combined into stunning scientific
              visualizations and tours. As am ambassador, I have used the WWT at
              multiple American Astronomical Society meetings, New York Science
              Festivals, and Cambridge Explores the Universe Festivals to engage
              the youngest visitors, ages 5-10, with astronomy research.
            </p>
          </div>
          <div className="carousel center">
            <Carousel
              autoPlay={true}
              interval={5000}
              showStatus={false}
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={true}
            >
              {images3.map((image) => (
                <img
                  src={image}
                  alt="Carousel Slide"
                  key={image}
                  height="auto"
                  width="100%"
                  loading="lazy"
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleSchoolModal;
