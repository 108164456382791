import React, { useState, useEffect } from "react";

import Header from "../../components/header/header.component";
import BiographySection from "../../components/sections/biography-section/biography-section.component";
import ResearchSection from "../../components/sections/research-section/research-section.component";
import OutreachSection from "../../components/sections/outreach-section/outreach-section.component";
import MediaSection from "../../components/sections/media-section/media-section.component";
import ContactSection from "../../components/sections/contact-section/contact-section.component";
import Signature from "../../components/signature/signature.component";

import "./home-page.styles.scss";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    if (loadedImages === 2) {
      setLoading(false);
    }
  }, [loadedImages]);

  return (
    <div>
      <div
        className="centered"
        style={{
          visibility: loading ? "visible" : "hidden",
          position: "fixed",
          height: "100vh",
        }}
      >
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        <Header />
        <div className="welcome-section">
          <div className="welcome-content-container">
            <img
              src={
                "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/welcome/jgarciamejia-portrait.png"
              }
              alt="alt"
              onLoad={() => setLoadedImages((v) => v + 1)}
            />
            <div className="welcome-content">
              <h1>Juliana García-Mejía</h1>
              <hr />
              <h2>Combined 51 Pegasi B &</h2>
              <h2>Pappalardo Postdoctoral Fellow at MIT</h2>
              <h2>
                PI of the <i>Tierras</i> Observatory
              </h2>
            </div>
          </div>
          <div className="welcome-background">
            <img
              src={
                "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/welcome/welcome-background.jpg"
              }
              alt="alt"
              onLoad={() => setLoadedImages((v) => v + 1)}
            />
          </div>
        </div>
        <BiographySection />
        <ResearchSection />
        <OutreachSection />
        <MediaSection />
        <ContactSection />
        <Signature />
      </div>
    </div>
  );
};

export default HomePage;
