import "./App.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./pages/home/home-page.component";
import FiosPage from "./pages/fios/fios-page.component";
import TierrasPage from "./pages/tierras/tierras-page.component";
import PageNotFound from "./pages/error/404.component";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top.component";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/tierras" element={<TierrasPage />} />
          <Route path="/fios" element={<FiosPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
