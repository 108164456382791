import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./outreach-modal.styles.scss";

const images = [
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/1.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/2.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/3.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/4.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/5.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/6.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/7.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/8.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/9.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/highschool/10.jpg",
];

const HighSchoolModal = () => {
  return (
    <div className="outreach-modal-container">
      <div className="outreach-modal-body">
        <h2>High School Teaching & Outreach</h2>
        <p>
          <b>
            Recent studies have found that the participation of underrepresented
            students in STEM intervention programming during high school leads
            to a greater sense of science self-efficacy, and therefore STEM
            career motivation, especially when said programs are set in learning
            environments that are meaningful to them.{" "}
          </b>
        </p>
        <p>
          Leveraging my research experience and bilingual background, I have
          developed approximately 27 experiment-based workshops in English and
          Spanish for students in Latin America and the larger Boston area. From
          building their own solar telescopes to measure the diameter of the
          Sun, to translating and using the{" "}
          <a href="https://waps.cfa.harvard.edu/microobservatory/diy/index.php">
            DIY Planet Search Website
          </a>{" "}
          to program robotic telescopes and discover their own exoplanets, I
          encourage students to learn by building and doing in exciting and
          meaningful settings.{" "}
        </p>
        <p>
          I am particularly proud of my experience leading a week-long workshop
          in my hometown, Medellín (Colombia), as part of{" "}
          <a href="https://clubesdeciencia.co/">Clubes de Ciencia Colombia</a>.
          Clubes is a worldwide initiative aimed at bringing high-quality STEM
          education to underserved communities. You can read more about my
          experience{" "}
          <a href="https://astronomy.fas.harvard.edu/clubes-de-ciencia-juliana-garcia-mejia">
            here
          </a>
          , and check out some of my favorite pictures/videos with the students
          below.{" "}
        </p>
        <div className="p40">
          <Carousel
            showArrows={true}
            autoPlay={true}
            interval={5000}
            showStatus={false}
            infiniteLoop={true}
            showThumbs={false}
          >
            {images.map((image) => (
              <img
                src={image}
                alt="Carousel Slide"
                key={image}
                height="auto"
                width="100%"
                loading="lazy"
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default HighSchoolModal;
