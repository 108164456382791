import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faBuilding } from "@fortawesome/free-solid-svg-icons";

import "./contact-section.styles.scss";

const ContactSection = () => {
  return (
    <div className="contact section centered">
      <div className="section-container">
        <h1>CONTACT</h1>
        <div className="contact-items">
          <div>
            <a href="mailto:jgarciam@mit.edu">
              <FontAwesomeIcon
                icon={faEnvelope}
                size="3x"
                color="rgb(187, 187, 187)"
              />
            </a>
            <p>jgarciam@mit.edu</p>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/in/jgarciamejia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="3x"
                color="rgb(187, 187, 187)"
              />
            </a>
            <p>Juliana García-Mejía</p>
          </div>
          <div>
            <a
              href="https://github.com/jgarciamejia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faGithub}
                size="3x"
                color="rgb(187, 187, 187)"
              />
            </a>
            <p>jgarciamejia</p>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faBuilding}
              size="3x"
              color="rgb(187, 187, 187)"
            />
            <p>
              MIT Kavli Institute for Astrophysics & Space Research <br />
              70 Vassar St. <br />
              Cambridge, MA 02139
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
