import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./outreach-modal.styles.scss";

const images = [
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/1.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/2.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/3.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/5.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/6.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/7.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/8.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/9.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/10.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/11.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/12.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/13.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/14.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/15.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/16.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/17.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/18.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/19.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/20.jpg",
  "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/21.jpg",
];

const LogoContestModal = () => {
  return (
    <div className="outreach-modal-container">
      <h2>
        <i>Tierras</i> Logo Outreach
      </h2>
      <p>
        As PI of the <i>Tierras</i> Observatory, I had the privilege of naming
        my facility and choosing its logo. I decided to turn this privilege into
        an opportunity to engage underrepresented students with <i>Tierras</i>{" "}
        and its science goals. In 2019, I launched Concurso Misión Tierras, a
        contest for students in Colombia to submit designs for the observatory’s
        logo.
      </p>
      <p>I received over 150 designs, some of which you can check out below:</p>
      <div className="centered">
        <div className="carousel-wrapper-400">
          <Carousel
            autoPlay={true}
            interval={5000}
            showStatus={false}
            infiniteLoop={true}
            showThumbs={false}
            showIndicators={false}
          >
            {images.map((image) => (
              <img
                src={image}
                alt="Logo Contest Finalist"
                key={image}
                max-height="400px"
              />
            ))}
          </Carousel>
        </div>
      </div>
      <p>
        The winning logo was designed by Ana Milena Aranceta, a freshman at
        Universidad Pontificia Bolivariana, in Medellín, Colombia. Ana is a
        talented graphic designer, film editor and engineering student. In her
        logo, yellow butterflies exit the <i>Tierras</i> dome and morph into the
        red dwarf stars we are targeting in our research. The yellow butterflies
        allude to a metaphor popularized by Latin-American author and Nobel
        laureate Gabriel García Marquez, and represent the passion Ana feels
        towards exoplanet research and the search for life in the Universe. At
        the same time, the butterflies are meant to honor Arizona, and highlight
        the remarkable diversity of yellow butterfly species present there.
      </p>
      <p>
        Ana and I collaborated with two talented Colombian graphic designers,
        Catalina Quiroga Mendoza and Laura Rodriguez Mesa, to refine her design
        and turn it into the observatory’s official logo:
      </p>
      <div className="centered">
        <img
          src={
            "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/modal-content/outreach/logocontest/official-logo.jpg"
          }
          alt="Tierras Logo"
        />
      </div>
      <p>
        To meet Ana Milena, Catalina, Laura, and some of the other
        semi-finalists, and hear what the contest meant for them, check out our
        concluding{" "}
        <a href="https://www.youtube.com/watch?v=4lHkUS3TTiM&ab_channel=RutaN">
          YouTube webinar
        </a>
        .
      </p>
    </div>
  );
};

export default LogoContestModal;
