import React from "react";

import "./outreach-modal.styles.scss";

const YoutubeModal = () => {
  return (
    <div className="outreach-modal-container">
      <h2>YouTube Teaching & Outreach</h2>
      <p>
        When I started grad school, I asked my advisor for a GoPro for research
        purposes. He was a bit skeptical, but bought me one anyway. I have used
        the GoPro to film every single day in the laboratory, recording the
        process of making Tierras a reality. This material has become a great
        way to engage scientists, engineers, and students alike with the
        challenges and joys of designing and building an entire observatory. I
        post these progress videos as blogs on the official and bilingual{" "}
        <a href="https://www.youtube.com/channel/UCB9FyAHqrgHDheoSXZ_lo6g">
          <i>Tierras</i> YouTube Channel
        </a>
        .
      </p>
      <p>
        Editing is not my favorite thing to do, but I will be posting tons of
        new videos in the next few months. Please stay tuned!
      </p>
    </div>
  );
};

export default YoutubeModal;
