import React from "react";
import TierrasContent from "../../components/tierras-content/tierras-content.component";

const TierrasPage = () => {
  return (
    <div>
      <TierrasContent />
    </div>
  );
};

export default TierrasPage;
