import React from "react";
import { useState, useEffect } from "react";
import "./header.styles.scss";
import { Link } from "react-scroll";

import useScrollPosition from "../../hooks/use-scroll-position/use-scroll-position";
import MobileHeader from "./mobile-header.component";

const Header = () => {
  const [visible, setVisbile] = useState(false);
  const scrollPosition = useScrollPosition();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 900;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (!visible && scrollPosition > 50) {
    setVisbile(true);
  }
  if (visible && scrollPosition < 50) {
    setVisbile(false);
  }

  return width > breakpoint ? (
    <div>
      {visible && (
        <div className="header">
          <Link to="welcome-section" smooth={true} className="header-title">
            Juliana García-Mejía
          </Link>
          <div className="header-link-container">
            <Link to="biography" smooth={true} className="header-link">
              Bio
            </Link>
            <Link to="research" smooth={true} className="header-link">
              Research
            </Link>
            <Link to="outreach" smooth={true} className="header-link">
              Outreach
            </Link>
            <Link to="media" smooth={true} className="header-link">
              Media
            </Link>
            <Link to="contact" smooth={true} className="header-link">
              Contact
            </Link>
            <a
              className="header-link"
              href="https://firebasestorage.googleapis.com/v0/b/jgarciamejia-website.appspot.com/o/cv.pdf?alt=media&token=a36fe2a8-10e7-45e3-8efe-1bcdd7725ffc"
              target="_blank"
              rel="noopener noreferrer"
            >
              CV
            </a>
          </div>
        </div>
      )}
    </div>
  ) : (
    visible && <MobileHeader />
  );
};

export default Header;
