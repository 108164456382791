import React from "react";

import "./outreach-modal.styles.scss";

const AdvisingModal = () => {
  return (
    <div className="outreach-modal-container">
      <h2>Advising Teaching & Outreach</h2>
      <p>
        While in undergrad, I found myself craving the mentorship of a more
        senior student who could relate to my experiences navigating academic
        spaces as a Latin American woman. As a graduate student, I have turned
        this craving into action by providing one-on-one advice and mentorship
        to a dozen students of color interested in pursuing careers in STEM.
        Here are some of the students I have advised over the years, and some of
        the mentorship programs I have participated of:
      </p>
      <ol>
        <li>
          Harvard Women in Science, Technology, Engineering, and Math (WiSTEM)
          Mentorship Program:
          <ul className="dashed">
            <li>Ana Humphrey, Harvard College ‘23</li>
            <li>Michelle Lin, Harvard College ‘24</li>
            <li>Jordan Sanchez, Harvard College ‘24</li>
            <li>
              Mma Ikwut-Ukwa, Harvard College ‘20 (now PhD student, Caltech
              Planetary Sciences)
            </li>
            <li>
              Mahlet Shiferaw, Harvard College ‘20 (now PhD student, Stanford
              Astrophysics)
            </li>
          </ul>
        </li>
        <li>
          Harvard Colombian Student Society Graduate Application Mentorship
          Program
          <ul className="dashed">
            <li>
              Sebastián Morales, McGill University ‘22 (now PhD student at
              Harvard Biology)
            </li>
            <li>
              Víctor Samuel Pérez Díaz, Universidad del Rosario ‘23 (now intern
              at CfA)
            </li>
          </ul>
        </li>
        <li>
          Women+ of Color Project
          <ul className="dashed">
            <li>
              Sideena Grace, Hamline University ‘21 (now S.M. student, MIT
              Aeronautics & Astronautics)
            </li>
          </ul>
        </li>
        <li>
          CfA Latino Initiative Program
          <ul className="dashed">
            <li>Keysha Rosado, UMass Lowell ‘22</li>
            <li>
              Elias Aguirre Contreras, UMass Lowell ‘21 (now Mechanical Engineer
              at CfA)
            </li>
          </ul>
        </li>
        <li>
          Independent Mentorship
          <ul className="dashed">
            <li>
              Sebastián Ruiz Lopera, Universidad EAFIT ‘21 (now PhD Student, MIT
              EECS)
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default AdvisingModal;
