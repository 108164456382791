import React from "react";

import "./outreach-modal.styles.scss";

const CollegeModal = () => {
  return (
    <div className="outreach-modal-container">
      <h2>College Teaching & Outreach</h2>
      <p>
        I have found teaching and tutoring in general, but particularly at the
        college level, to be one of the most rewarding aspects of my academic
        training.{" "}
        <b>
          My philosophy is simple: lead with humanity, charisma, and humor to
          foment a teaching environment where all students feel comfortable
          asking questions, check in with the students often to gauge their
          understanding, and use multi-media tools as a supplement, not a
          crutch.
        </b>
      </p>
      <h4>Harvard University Appointments</h4>
      <ol>
        <li>
          TEACHING FELLOW
          <p>I have served as teaching fellow for two courses:</p>
          <ul>
            <li>
              <b>Fall 2018:</b> Introductory Galactic & Extragalactic Astronomy
              (ASTRON-17).
            </li>
            <li>
              <b>Fall 2019:</b> The Big Questions in Astronomy (ASTRON-1).
            </li>
          </ul>
          <p>
            My responsibilities included preparing and delivering supplemental
            lectures and tutorials for course sections and labs, assisting
            students with homework questions during office hours, running review
            sessions prior to midterms and finals, offering one-on-one support
            for students, and grading coursework and examinations. ASTRON-1 was
            a 90-student class, requiring a team of 5 teaching fellows. I also
            acted as lead TF for this course, delegating responsibilities
            equitably and coordinating scheduling efforts related to office
            hours, course sections and labs, homework solution writing, and
            coursework grading.{" "}
          </p>
          <p>
            I was awarded the Derek Bok Center Certificate of Distinction in
            Teaching twice for my performance on these courses.{" "}
          </p>
        </li>
        <li>
          GUEST LECTURER
          <p>
            I have also been invited to deliver guest lectures at Harvard
            College, one of which was recorded and I link to below.{" "}
          </p>
          <ul>
            <li>
              <b>Fall 2019:</b> 1.25 hour lecture on the lives and deaths of
              low-mass stars. Watch it{" "}
              <a href="https://drive.google.com/open?id=1GMgR9a9h8FpjKSHzt0gbfF50lvsW54wD&authuser=juliana.garcia-mejia%40cfa.harvard.edu&usp=drive_fs">
                here
              </a>
              .
            </li>
            <li>
              <b>Summer 2022:</b> 2 hour lecture on the design principles of the
              Tierras Observatory.
            </li>
          </ul>
        </li>
        <li>
          TUTORING
          <p>
            Between 2015 and 2018, I spent over 100 hours tutoring students in
            STEM-related subjects, as well as in Spanish courses. I tutored
            college students through the Harvard Bureau of Study Counsel,
            guiding them through class materials and problems sets in Math 1b
            (Calculus, Series, and Differential Equations), Physics 143 A
            (Quantum Mechanics I), and Astronomy 16 (Introductory Solar and
            Planetary Astronomy). I also tutored Harvard Summer School students
            through class materials and problems sets in Physics 17215 (An
            Introduction to Astrophysics), and a variety of Spanish courses.{" "}
          </p>
        </li>
      </ol>
    </div>
  );
};

export default CollegeModal;
