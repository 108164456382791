import React from "react";
import "./outreach-section.styles.scss";

import ThumbnailLink from "../../thumbnail-link/thumbnail-link.component";

const OutreachSection = () => {
  const items = [
    {
      id: "middleschool",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/elementaryandmiddle.jpg",
      caption: "Elementary & Middle School",
      hasModal: true,
    },
    {
      id: "highschool",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/highschool.jpg",
      caption: "High School",
      hasModal: true,
    },
    {
      id: "college",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/college.jpg",
      caption: "College Teaching",
      hasModal: true,
    },
    {
      id: "advising",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/advisingandmentoring.jpg",
      caption: "Advising & Mentoring",
      hasModal: true,
    },
    {
      id: "youtube",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/youtube.jpg",
      caption: "Tierras YouTube Channel",
      hasModal: true,
    },
    {
      id: "logocontest",
      imageUrl:
        "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/teaching/logocontest.jpg",
      caption: "Tierras Logo Contest",
      hasModal: true,
    },
  ];

  return (
    <div className="outreach section centered">
      <div className="section-container">
        <h1>TEACHING AND OUTREACH</h1>
        <p>
          I am enamored with the question of whether life exists elsewhere in
          the universe, and I also believe that the quest for an answer belongs
          to all of humanity. At the same time, I know from experience that
          navigating academic spaces as an underrepresented minority can be
          exacting.
        </p>
        <p>
          Through my teaching and outreach efforts, I seek to increase the
          diversity of the workforce building the instruments that will enable
          us to look for signs of life on other planets, while simultaneously
          advocating for meaningful and creative changes so all of us can
          flourish and thrive in the workplace. Read more about my efforts
          below.
        </p>
        <div className="centered">
          <div className="thumbnail-link-collection">
            {items.map((item) => (
              <ThumbnailLink item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutreachSection;
