import React from "react";
import "./thumbnail-link.styles.scss";
import { useState } from "react";
import Modal from "react-modal";
import HighSchoolModal from "../modals/outreach/high-school-modal.component";
import MiddleSchoolModal from "../modals/outreach/middle-school-modal.component";
import CollegeModal from "../modals/outreach/college-modal.component";
import AdvisingModal from "../modals/outreach/advising-modal.component";
import LogoContestModal from "../modals/outreach/tierras-logo-contest-modal.component";
import YoutubeModal from "../modals/outreach/youtube-modal.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  content: {
    top: "10%",
    backgroundColor: "#fffdf8",
    padding: "10%",
  },
};

Modal.setAppElement("#root");

const ThumbnailLink = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "middleschool":
        return <MiddleSchoolModal />;
      case "highschool":
        return <HighSchoolModal />;
      case "college":
        return <CollegeModal />;
      case "advising":
        return <AdvisingModal />;
      case "logocontest":
        return <LogoContestModal />;
      case "youtube":
        return <YoutubeModal />;
      default:
        return null;
    }
  };

  return (
    <div>
      {item.hasModal ? (
        <div>
          <div className="thumbnail-link" onClick={() => openModal()}>
            <div className="img-container">
              <img src={item.imageUrl} alt="Thumbnail" />
            </div>
            <div className="thumbnail-link-caption">{item.caption}</div>
          </div>
          {item.hasModal && (
            <Modal
              isOpen={showModal}
              onRequestClose={() => closeModal()}
              style={customStyles}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                color="#00c8ff"
                size="2x"
                onClick={() => closeModal()}
              />
              {renderSwitch(item.id)}
            </Modal>
          )}
        </div>
      ) : (
        <div>
          <a
            className="thumbnail-link"
            href={item.link}
            target="_blank"
            rel="noreferrer"
          >
            <div className="img-container">
              <img src={item.imageUrl} alt="Thumbnail" />
            </div>
            <div className="thumbnail-link-caption" />
          </a>
        </div>
      )}
    </div>
  );
};

export default ThumbnailLink;
