import React from "react";
import "./media-section.styles.scss";
import ThumbnailLink from "../../thumbnail-link/thumbnail-link.component";

const media = [
  {
    id: "finalfrontier",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/finalfrontier.jpg",
    caption: "",
    hasModal: false,
    link: "https://gsas.harvard.edu/news/stories/final-frontier",
  },
  {
    id: "harvardgazette",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/harvardgazette.jpg",
    caption: "",
    hasModal: false,
    link: "",
  },
  {
    id: "cbsnews",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/cbsnews.jpg",
    caption: "",
    hasModal: false,
    link: "https://www.cbsnews.com/boston/news/melrose-high-school-senior-webinar-highlight-women-role-models-space-industry/",
  },
  {
    id: "elcolombiano",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/elcolombiano.jpg",
    caption: "",
    hasModal: false,
    link: "https://www.elcolombiano.com/colombia/educacion/clubes-cientificos-en-medellin-GH8900945",
  },
  {
    id: "harvarduniversity",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/harvarduniversity.jpg",
    caption: "",
    hasModal: false,
    link: "https://drclas.harvard.edu/event/are-we-alone-universe-giant-magellan-telescope-las-campanas-chile",
  },
  {
    id: "podcast",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/podcast.jpg",
    caption: "",
    hasModal: false,
    link: "https://open.spotify.com/episode/2W2kmd0la8Ly6R0MBiYadz?si=soz8oZIARWiWP6Ykr0IMKg&nd=1",
  },
  {
    id: "harvardmagazine",
    imageUrl:
      "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/harvardmagazine.jpg",
    caption: "",
    hasModal: false,
    link: "https://www.harvardmagazine.com/2022/04/harvard-horizons-spotlights-seven-scholars",
  },
];

const MediaSection = () => {
  return (
    <div className="media section centered">
      <div className="section-container">
        <h1>MEDIA</h1>
        <h2>Harvard Horizons</h2>
        <p>
          In 2022, I was selected as one of eight{" "}
          <a href="https://gsas.harvard.edu/academics/professional-development/harvard-horizons">
            Harvard Horizon Scholars
          </a>{" "}
          (HHS). As a HHS, I received in-depth, personalized mentoring and
          coaching designed to enhance my public speaking skills. At the end of
          the program, I presented my research in front of members of the
          university on the beautiful Sanders Theater stage.
        </p>
        <div className="featured-media-links">
          <p>
            <a href="https://www.youtube.com/watch?v=RsFBqgdHuog&ab_channel=HarvardGraduateSchoolofArtsandSciences">
              Watch my presentation
            </a>
          </p>
          <p>
            <a href="https://gsas.harvard.edu/news/stories/harvard-horizons-podcast-search-other-earths">
              Listen to my podcast
            </a>
          </p>
          <p>
            <a href="https://gsas.harvard.edu/profiles/juliana-garc%C3%ADa-mej%C3%ADa">
              Read about my path to HH
            </a>
          </p>
        </div>
        <img
          src={
            "https://jgarciamejia.nyc3.digitaloceanspaces.com/images/media/harvardhorizons.jpg"
          }
          alt="Thumbnail"
          width={"100%"}
        />
        <div className="mt120">
          <h2>Other Media</h2>
          <div className="thumbnail-link-collection">
            {media.map((item) => (
              <ThumbnailLink item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaSection;
