import React from "react";

const PageNotFound = () => {
  return (
    <div className="error-page">
      <h1>Oops!</h1>
      <h2>Sorry, this page doesn't exist.</h2>
    </div>
  );
};

export default PageNotFound;
