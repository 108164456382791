import React from "react";
import FiosContent from "../../components/fios-content/fios-content.component";

const FiosPage = () => {
  return (
    <div>
      <FiosContent />
    </div>
  );
};

export default FiosPage;
