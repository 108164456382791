import React, { useState } from "react";
import "./fios-content.styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const FiosContent = () => {
  const [loading, setLoading] = useState(true);

  const mediaLoaded = () => {
    setLoading(false);
  };

  return (
    <div>
      <div
        className="centered"
        style={{
          visibility: loading ? "visible" : "hidden",
          position: "fixed",
          height: "100vh",
        }}
      >
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        <div className="back-arrow">
          <Link to="/">
            <FontAwesomeIcon icon={faArrowLeft} color="#00c8ff" size="2x" />
          </Link>
        </div>
        <div className="fios-content-container">
          <div className="fios-content">
            <h1>The FIOS Project</h1>
            <p>
              FIOS is a Fabry-Perot based Instrument for Oxygen Searches,
              designed for use with G-CLEF on the Giant Magellan Telescope (GMT)
              to detect molecular oxygen in exoplanet atmospheres. FIOS proposes
              a novel approach to reaching extremely-high resolution
              spectroscopy (R {"> "}
              300,000) over a narrow wavelength range (30 nm) by means of a
              low-cost Fabry-perot interferometer (FPI) array placed in front of
              a modest-to-high resolution spectrograph.
            </p>
            <p>
              My roles as co-investigator have ranged from helping to develop
              the FIOS science case and determining the ideal parameters of
              observed systems to building the first prototype in the lab.
            </p>
            <p>Read more about FIOS via the following publications:</p>
            <div className="fios-links-container">
              <p>
                <a href="https://iopscience.iop.org/article/10.3847/1538-3881/ab21d7/pdf">
                  The scientific motivation
                </a>
              </p>
              <p>
                <a href="https://ui.adsabs.harvard.edu/abs/2018ApJ...861...79B/abstract">
                  The instrument concept
                </a>
              </p>
              <p>
                <a href="https://arxiv.org/pdf/2012.04007.pdf">
                  Lab technology demonstration using two-arm prototype
                </a>
              </p>
            </div>
            <p>
              In an upcoming paper, we summarize our latest experiment feeding
              the two-arm FIOS prototype with sunlight, and resolving a solar
              spectrum with very high resolution. Stay tuned to learn more.
            </p>
          </div>
          <div className="fios-background-container">
            <video
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={`true`}
              src="https://jgarciamejia.nyc3.digitaloceanspaces.com/videos/fios-background.mp4"
              type="video/mp4"
              onLoadedData={() => {
                mediaLoaded();
              }}
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiosContent;
